const theme = {
  // -------------------------- //
  // --- THEMING ATTRIBUTES --- //
  // -------------------------- //
  breakpoints: ['480px', '768px', '1024px', '1280px'],
  // Core: https://coolors.co/35414d-095463-2f475f-543a5b-8e2b1d-8f591d-8f8b1d-638f1d
  // Dataviz: https://coolors.co/52718e-1d9cb5-4a77a4-8d5499-ce4836-ce8736-cec736-94ce36
  colors: {
    text: '#202020',
    background: '#f2f7f8',
    primary: '#095463',
    black: '#202020',
    white: '#fefefe',
    brand: {
      charcoal: '#35414d',
      petrol: '#095463',
      indigo: '#2f475f',
      plum: '#543a5b',
      blood: '#8e2b1d',
      tan: '#8f591d',
      olive: '#8f8b1d',
      avocado: '#638f1d',
    },
    charcoal: {
      50: '#f3f5f7',
      100: '#e7ebee',
      200: '#cfd6de',
      300: '#7b8fa3',
      400: '#546678',
      500: '#35414d',
      600: '#323d48',
      700: '#2a333c',
      800: '#212930',
      900: '#111418',
      bright: '#52718e',
    },
    gray: { // -> charcoal
      50: '#f3f5f7',
      100: '#e7ebee',
      200: '#cfd6de',
      300: '#7b8fa3',
      400: '#546678',
      500: '#35414d',
      600: '#323d48',
      700: '#2a333c',
      800: '#212930',
      900: '#111418',
      bright: '#52718e',
    },
    petrol: {
      50: '#f2f7f8',
      100: '#e3eff2',
      200: '#c4dbdf',
      300: '#70c5d7',
      400: '#0e7f95',
      500: '#095463',
      600: '#073f4b',
      700: '#053038',
      800: '#032025',
      900: '#021013',
      bright: '#1d9cb5',
    },
    indigo: {
      500: '#2f475f',
      bright: '#4a77a4',
    },
    plum: {
      50: '#ede6ef',
      100: '#c1a8c7',
      200: '#9d76a7',
      300: '#7e5889',
      400: '#674870',
      500: '#543a5b',
      600: '#45304b',
      700: '#39283e',
      800: '#2e2032',
      900: '#171019',
      bright: '#8d5499',
    },
    purple: { // -> plum
      50: '#ede6ef',
      100: '#c1a8c7',
      200: '#9d76a7',
      300: '#7e5889',
      400: '#674870',
      500: '#543a5b',
      600: '#45304b',
      700: '#39283e',
      800: '#2e2032',
      900: '#171019',
      bright: '#8d5499',
    },
    blood: { // -> red
      50: '#f8e0dd',
      100: '#eaa399',
      200: '#e07567',
      300: '#d95645',
      400: '#ba3726',
      500: '#8e2b1d',
      600: '#772318',
      700: '#661e15',
      800: '#44140e',
      900: '#220a07',
      bright: '#ce4836',
    },
    red: {
      50: '#f8e0dd',
      100: '#eaa399',
      200: '#e07567',
      300: '#d95645',
      400: '#ba3726',
      500: '#8e2b1d',
      600: '#772318',
      700: '#661e15',
      800: '#44140e',
      900: '#220a07',
      bright: '#ce4836',
    },
    tan: {
      500: '#8f591d',
      bright: '#ce8736',
    },
    olive: {
      500: '#8f8b1d',
      bright: '#cec736',
    },
    avocado: {
      500: '#638f1d',
      bright: '#94ce36',
    },
    modes: {
      dark: {},
    }
  },
  fonts: {
    body: 'Lato, sans-serif',
    heading: 'Lato, serif',
    monospace: '"Space Mono", monospace',
  },
  // fontSizes: [10, 11, 12, 14, 16, 18, 19, 20, 24, 32, 48, 64],
  fontSizes: {
    1: 10,
    2: 11,
    3: 12,
    4: 14,
    5: 16,
    6: 18,
    7: 19,
    8: 20,
    9: 24,
    10: 28,
    11: 32,
    12: 40,
    13: 48,
    14: 60,
    15: 72,
    body: 16,
  },
  fontWeights: {
    light: 300,
    body: 300,
    normal: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.3,
    heading: 1.3,
    none: 1,
    tightest: 1.1,
    tighter: 1.2,
    tight: 1.3,
    normal: 1.4,
    loose: 1.5,
    looser: 1.6,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  radii: {
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 10,
  },
  // space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60],
  //      0^ 1^ 2^ 3^  4^  5^  6^  7^  8^  9^  10^ e.g. multiply by 4
  // Remember that default (mobile) spacing is slightly bespoke. See Figma.
  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 20,
    6: 24,
    7: 28,
    8: 32,
    9: 36,
    10: 40,
    11: 44,
    12: 48,
    13: 52,
    14: 56,
    15: 60,
    def: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 20,
    defGap: 12,
    smGap: 16,
    mdGap: 24,
    lgGap: 32,
    xlGap: 40,
    defVert: 18,
    smVert: 24,
    mdVert: 36,
    lgVert: 48,
    xlVert: 60,
  },
  sizes: {
    sm6: '100%',
    sm9: '100%',
    sm12: '100%',
    md6: (6 * 60) - 24,
    md9: (9 * 60) - 24,
    md12: (12 * 60) - 24,
    lg6: (6 * 80) - 32,
    lg9: (9 * 80) - 32,
    lg12: (12 * 80) - 32,
    xl6: (6 * 100) - 40,
    xl9: (9 * 100) - 40,
    xl12: (12 * 100) - 40,
  },
  // ----------------------------------------- //
  // --- STYLES FOR MARKDOWN AND OTHER CSS --- //
  // ----------------------------------------- //
  styles: {
    hr: {
      width: '100%',
    },
    root: {
      // Core styles
      fontFamily: 'body',
      fontWeight: 'body',
      'h1, h2, h3, h4, h5, h6, p': {
        marginBlockStart: 0,
        '>.draft': {
          color: 'red.500',
        }
      },
      'p': {
        marginBlockEnd: [4, null, 4, 5],
        mb: [4, null, 4, 5],
        fontSize: [6, 6, 6, 6, 7],
        lineHeight: 'normal',
      },
      'ul, ol': {
        marginBlockStart: 0,
        marginBlockEnd: [4, null, 4, 5],
        paddingInlineStart: '14px',
        fontSize: [6, 6, 6, 6, 7],
        lineHeight: 'normal',
        'li': {
          mb: [2, 2, 2, 3, 3],
          '::marker': {
            color: 'petrol.500',
          }
        },
        '&.compact': {
          fontSize: [4, 4, 4, 5, 5],
          'li': {
            mb: [1, null, null, 2, 2],
          },
        },
      },
      'a': {
        color: 'petrol.500',
        ':visited': {
          color: 'petrol.500',
        },
        ':hover': {
          color: 'petrol.900',
        },
      },

      // Utility styles
      '.mobileOnly, .mobile-only': {
        display: ['inherit', null, 'none', null, null],
      },
      '.defOnly, .def-only': {
        display: ['inherit', 'none', null, null, null],
      },
      '.smOnly, .sm-only': {
        display: ['none', 'inherit', 'none', null, null],
      },
      '.mdOnly, .md-only': {
        display: ['none', null, 'inherit', 'none', null],
      },
      '.lgOnly, .lg-only': {
        display: ['none', null, null, 'inherit', 'none'],
      },
      '.xlOnly, .xl-only': {
        display: ['none', null, null, null, 'inherit'],
      },
      '.desktopOnly, .desktop-only': {
        display: ['none', null, 'inherit', null, null],
      },
      '.svg-inline--fa': {
        color: 'petrol.700',
      },
      '.faCharcoal, .fa-charcoal': { color: 'charcoal.500' },
      '.faPetrol, .fa-petrol': { color: 'petrol.500' },
      '.faPetrolBright, .fa-petrol-bright': { color: 'petrol.bright' },
      '.faIndigo, .fa-indigo': { color: 'indigo.500' },
      '.faIndigoBright, .fa-indigo-bright': { color: 'indigo.bright' },
      '.faPlum, .fa-plum': { color: 'plum.500' },
      '.faRed, .fa-red': { color: 'red.500' },
      '.faTan, .fa-tan': { color: 'tan.500' },
      '.faOlive, .fa-olive': { color: 'olive.500' },
      '.faAvocado, .fa-avocado': { color: 'avocado.500' },
      '.faWhite, .fa-white': { color: 'white' },
      
      // Component styles
      '.big-message': {
        p: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap'],
        '> div': {
          gap: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap'],
        },
        '.icon': {
          fontSize: [9, 9, 11, 12, 14],
        },
        '.heading': {
          fontSize: [9, 10, null, null, 11],
        },
        '.message': {
          fontSize: [5, null, 7, null, 9],
        },
      },
      '.show-icon-on-mobile': {
        '.icon': {
          mr: [0, 0, 0],
          'svg': {
            display: ['inline-block', 'inline-block', 'inline-block'],
          },
        }
      },
      
      // Page specific styles
      '.page-article-single': {
        'article': {
          lineHeight: 'loose',
          'header': {
            gridColumnStart: ['span 12', null, 'span 12', 'span 12', 'span 12'],
            'h1': {
              m: 0,
            }
          },
          'section, aside': {
            '&.observations': {
              'ul': {
                fontSize: [5, 5, 5, 5, 5],
                'li': {
                  mb: [1, 1, 1, 1, 1],
                  lineHeight: 'tighter',
                },
              }
            },
          },
          'section': {
            '.dataviz .chart-wrapper svg': {
              width: ['auto', null, 'md12', 'lg12', 'xl12'],
            },
            '&.intro': {
              'p:first-of-type': {
                maxWidth: ['unset', null, null, null, 860],
                color: 'petrol.700',
                fontSize: [6, null, null, null, 9],
                fontWeight: 'normal',
                lineHeight: 'normal',
              },
              'p:last-of-type': {
                mb: 0,
                marginBlockEnd: 0,
              },
            },
          },
          'aside': {
            'p:last-of-type': {
              marginBlockEnd: 0,
              marginBottom: 0,
            }
          },
          '&.with-sidebar': {
            'section': {
              gridColumnStart: ['span 12', null, 'span 12', 'span 8', 'span 8'],
            },
            'aside': {
              gridColumnStart: ['span 12', null, 'span 12', 'span 4', 'span 4'],
              borderColor: 'gray.200',
              borderRadius: [0, null, 'sm'],
              borderStyle: ['none', null, 'solid'],
              borderWidth: [0, null, '1px'],
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
              p: [0, null, 3, null, 4],
              pt: [4, null, 3, null, 4],
              'h3': {
                fontSize: [5, null, null, null, 8],
              },
              'ul': {
                'li': {
                  color: 'gray.700',
                  fontSize: [4, null, 5, null, null],
                  mb: 2,
                }
              }
            },
          }
        },
      }
    },
    h1: {
      variant: 'text.h1',
    },
    h2: {
      variant: 'text.h2',
    },
    h3: {
      variant: 'text.h3',
    },
    h4: {
      variant: 'text.h4',
    },
  },
  // ------------------ //
  // --- COMPONENTS --- //
  // ------------------ //
  alerts: {
    primary: {
      bg: 'brand.petrol',
    },
    charcoal: {
      bg: 'brand.charcoal',
    },
    indigo: {
      bg: 'brand.indigo',
    },
    plum: {
      bg: 'brand.plum',
    },
    blood: {
      bg: 'brand.blood',
    },
    tan: {
      bg: 'brand.tan',
    },
    olive: {
      bg: 'brand.olive',
    },
    avocado: {
      bg: 'brand.avocado',
    },
  },
  box: {},
  buttons: {
    primary: {
      color: 'background',
      bg: 'primary',
      '&:hover': {
        bg: 'text',
      }
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
  },
  container: {
    maxWidth: ['calc(100% - 20px)', 'calc(100% - 32px)', 720, 960, 1200],
    mb: ['defVert', 'smVert', 'mdVert', 'lgVert', 'xlVert'],
    px: ['def', 'sm', 'md', 'lg', 'xl'],
  },
  cards: {
    gridArticle: {
      p: [1, 2, 3, 4, 5],
    }
  },
  flex: {
    h: {
      flexDirection: 'row',
      gap: [3, 3, 3, 4, 5],
    },
    hCentre: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: [3, 3, 3, 4, 5],
    },
    v: {
      flexDirection: 'column',
      gap: [3, 3, 3, 4, 5],
    },
    vCentre: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: [3, 3, 3, 4, 5],
    },
    textList: {
      flexDirection: ['column', null, 'row'],
      alignItems: ['flex-start', null, 'center'],
      gap: [1, 1, 3, 4, 5],
    },
  },
  grids: {
    bcuk: {
      gridTemplateColumns: ['repeat(12, 1fr)', 'repeat(12, 1fr)', 'repeat(12, 36px)', 'repeat(12, 48px)', 'repeat(12, 60px)'],
      gridGap: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap'],
      // rowGap: [2, 2, 2],
      maxWidth: ['calc(100% - 20px)', 'calc(100% - 32px)', 720, 960, 1200],
      mt: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap'],
      mx: 'auto',
      px: ['def', 'sm', 'md', 'lg', 'xl'],
      'hr': {
        gridColumnStart: ['span 12', null, null, null, null],
        m: 1,
        borderBottom: 'none',
      },
      '&.noRowGap, &.no-row-gap': {
        gridRowGap: [0, 0, 0, 0, 0],
      },
      '> .noBottomGap, > .no-bottom-gap': {
        mb: [-3, -4, -6, -8, -10],
      },
      '> .lessBottomGap, > .less-bottom-gap': {
        mb: [-2, -2, -3, -4, -5],
      },
      '> .withTopSpace, > .with-top-space': {
        mt: [3, 3, 4, 4, 5],
      },
      '> .withTopBorder, > .with-top-border': {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderColor: 'petrol.200',
        mt: ['defGap', 'smGap', 0, 0, 0],
        pt: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap'],
      },
    },
    gridSwatches: {
      gridTemplateColumns: ['repeat(4, 1fr)', 'repeat(5, 1fr)', 'repeat(10, 1fr)'],
      gridGap: [1,3,3,4,5],
      mx: [0, '6px', 3, 4, 5]
    },
    gridDense: {
      gridTemplateColumns: ['repeat(4, 1fr)', null, 'repeat(4, 1fr)'],
      gridGap: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap']
    },
    gridArticles: {
      gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
      gridGap: ['defGap', 'smGap', 'mdGap', 'lgGap', 'xlGap']
    },
    header: {},
    footer: {
      gridTemplateColumns: ['auto', null, '3fr 1fr', null, '5fr 1fr'],
      gridGap: [1,2,3,4,10],
      py: [1, 2, null, 3, null]
    },
  },
  layout: {
    // e.g. Containers
    noPadding: {
      variant: 'container',
      p: 0,
      px: 0,
    },
    fullWidth: {
      variant: 'container',
      maxWidth: '100%'
    },
    header: {
      variant: 'container',
      mt: 0,
      mb: ['defVert', 'smVert', 'mdVert', 'lgVert', 'xlVert'],
      p: [0, 0, 0, 0, 0],
    },
    footer: {
      variant: 'container',
      mb: [4, null, null, 6],
      p: 0
    },
    subGrid: {
      display: 'contents',
      // gridColumnStart: ['span 12', null, null, null, null],
    },
    full: {
      gridColumnStart: ['span 12', null, null, null, null],
      'hr': {
        gridColumnStart: ['span 12', null, null, null, null],
      },
      '.vega-embed ': {
        display: ['none', 'inline-block', null, null, null],
        '.chart-wrapper svg': {
          width: ['auto', 'sm12', 'md12', 'lg12', 'xl12'],
          height: 'auto',
        },
      },
    },
    fiveSixths: {
      gridColumnStart: ['span 12', null, null, 'span 10', 'span 10'],
    },
    threeQuarters: {
      gridColumnStart: ['span 12', null, null, 'span 9', 'span 9'],
      '.vega-embed': {
        display: ['none', 'inline-block', null, null, null],
        '.chart-wrapper svg': {
          width: ['auto', null, 'md12', 'lg9', 'xl9'],
          height: 'auto',
        },
      },
    },
    twoThirds: {
      gridColumnStart: ['span 12', null, null, 'span 8', 'span 8'],
      '.vega-embed': {
        display: ['none', 'inline-block', null, null, null],
        '.chart-wrapper svg': {
          width: ['auto', null, 'md12', 'lg8', 'xl8'],
          height: 'auto',
        },
      },
    },
    half: {
      gridColumnStart: ['span 12', null, null, 'span 6', 'span 6'],
      '.vega-embed': {
        display: ['none', 'inline-block', null, null, null],
        '.chart-wrapper svg': {
          width: ['auto', null, 'md12', 'lg6', 'xl6'],
          height: 'auto',
        },
      },
    },
    third: {
      gridColumnStart: ['span 6', null, null, 'span 4', 'span 4'],
    },
    quarter: {
      gridColumnStart: ['span 6', null, null, 'span 3', 'span 3'],
    },
    sixth: {
      gridColumnStart: ['span 4', null, null, 'span 2', 'span 2'],
    },
    textShout: {
      variant: 'container',
      p: 0,
      bg: 'petrol.700',
      color: 'white',
    },
  },
  links: {
    linkButton: {
      display: 'inline-block',
      borderRadius: 'md',
      mb: [2, null, null, null, 4],
      py: [2, null, null, null, 3],
      px: [2, null, null, null, 4],
      bg: 'petrol.500',
      fontSize: [5, null, null, null, 4],
      textDecoration: 'none',
      '.leftIcon': {
        pr: [2, null, null, null, 4],
      },
      '.rightIcon': {
        pl: [2, null, null, null, 4],
      },
      '.label': {
        color: 'white',
        fontSize: [5, null, null, null, 7],
        fontWeight: 'normal',
      },
      '.subLabel': {
        color: 'white',
        fontSize: [3, null, null, null, 4],
        fontWeight: 'normal',
      },
      ':hover': {
        bg: 'petrol.700',
        cursor: 'pointer',
      },
    },
  },
  messages: {
    default: {
      background: 'petrol.100',
      borderColor: 'petrol.200',
      color: 'petrol.700',
      svg: {
        color: 'petrol.500',
      }
    },
    transparent: {
      background: 'transparent',
      borderColor: 'petrol.200',
      color: 'petrol.700',
      svg: {
        color: 'petrol.500',
      }
    },
    charcoal: {
      background: 'charcoal.100',
      borderColor: 'charcoal.200',
      color: 'charcoal.800',
      svg: {
        color: 'charcoal.700',
      }
    },
    plum: {
      background: 'plum.50',
      borderColor: 'plum.100',
      color: 'plum.700',
      svg: {
        color: 'plum.700',
      }
    },
    red: {
      background: 'red.50',
      borderColor: 'red.100',
      color: 'red.700',
      svg: {
        color: 'red.700',
      }
    },
    notify: {
      background: 'petrol.200',
    },
    warning: {
      background: 'red.200',
    },
  },
  text: {
    default: {
      fontSize: 'inherit',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    h1: {
      color: 'petrol.700',
      fontSize: [9, 9, 10, 11, 12],
      fontWeight: 'bold',
      letterSpacing: '-0.02em',
      lineHeight: 'heading',
      mt: [6, null, 8, null, 10],
      mb: [3, null, 4, null, 5],
      '&:first-of-type': {
        mt: 0,
      },
    },
    h2: {
      color: 'petrol.700',
      fontSize: [8, 8, 9, 10, 11],
      fontWeight: 'bold',
      lineHeight: 'heading',
      mt: [4, null, 6, null, 8],
      mb: [2, null, 3, null, 4],
      '&:first-child': {
        mt: 0,
      },
    },
    h3: {
      color: 'petrol.700',
      fontSize: [6, 6, 6, 6, 9],
      fontWeight: 'bold',
      lineHeight: 'heading',
      mt: [2, 3],
      '&:first-child': {
        mt: 0,
      },
    },
    h4: {
      fontSize: [6, 6, 6, 6, 8],
      fontWeight: 400,
      lineHeight: 'heading',
      mt: [2, 3],
      '&:first-child': {
        mt: 0,
      },
    },
    articleDate: {
      color: 'brand.petrol',
      fontSize: [4, 4, 5, 5, 6],
      fontWeight: 'normal',
      lineHeight: 'heading',
    }
  },
}

export default theme