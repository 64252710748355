exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1711-internet-freedom-political-engagement-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1711-internet-freedom-political-engagement/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1711-internet-freedom-political-engagement-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1712-data-status-changed-in-society-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1712-data-status-changed-in-society/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1712-data-status-changed-in-society-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1712-sentiment-analysis-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1712-sentiment-analysis/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1712-sentiment-analysis-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1801-social-media-impact-offline-relationships-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1801-social-media-impact-offline-relationships/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1801-social-media-impact-offline-relationships-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1806-artisanry-craft-beer-culture-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1806-artisanry-craft-beer-culture/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1806-artisanry-craft-beer-culture-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1806-data-half-life-external-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1806-data-half-life-external/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1806-data-half-life-external-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1811-quantified-self-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1811-quantified-self/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1811-quantified-self-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1900-academic-article-template-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1900-academic-article-template/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1900-academic-article-template-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1901-algorithms-reproducing-stereotypes-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1901-algorithms-reproducing-stereotypes/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1901-algorithms-reproducing-stereotypes-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1901-quant-screen-use-affects-sleep-duration-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1901-quant-screen-use-affects-sleep-duration/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1901-quant-screen-use-affects-sleep-duration-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1905-network-analysis-twitter-data-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/1905-network-analysis-twitter-data/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-1905-network-analysis-twitter-data-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2007-automated-voice-services-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/2007-automated-voice-services/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2007-automated-voice-services-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2303-migrating-from-d-3-to-altair-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/2303-migrating-from-d3-to-altair/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2303-migrating-from-d-3-to-altair-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2303-visualising-cyclings-grand-tour-winners-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/2303-visualising-cyclings-grand-tour-winners/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2303-visualising-cyclings-grand-tour-winners-index-mdx" */),
  "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2409-hsw-hill-climb-series-index-mdx": () => import("./../../../src/pages/articles/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/articles/2409-hsw-hill-climb-series/index.mdx" /* webpackChunkName: "component---src-pages-articles-mdx-frontmatter-slug-js-content-file-path-articles-2409-hsw-hill-climb-series-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

